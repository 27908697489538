@charset "utf-8";

/*Large devices (desktops, less than 1200px)*/
@media (max-width: 1199.98px) {
  .hero-content h1 {
    font-size: 45px;
  }

  .hero-content p {
    font-size: 18px;
  }

  .hero-content button {
    font-size: 16px;
  }

  .what-wireswap h1 {
    font-size: 35px;
  }

  .what-wireswap p {
    font-size: 18px;
  }
}

/*Small devices (landscape Tablet, 992 and up)*/
@media (max-width: 991.98px) {
  .nav-container-fluid {
    display: none;
  }

  .mobile-navbar {
    display: block;
  }

  .hero-content h1 {
    font-size: 30px;
  }

  .hero-content p {
    font-size: 16px;
  }

  .hero-content button {
    font-size: 14px;
  }

  .what-wireswap h1 {
    font-size: 30px;
    padding: 0px;
  }

  .what-wireswap p {
    font-size: 17px;
    padding: 0px;
  }
}

/*Small devices (landscape phones, 576px and up)*/
@media (max-width: 767.98px) {
  .hero-content h1 {
    margin-top: 40px;
  }

  .hero-area img {
    margin-top: 20px;
  }

  .what-wireswap h1 {
    margin-top: 30px;
  }

  .token-content {
    padding-right: 0px;
  }

  .token-chart {
    padding-top: 30px;
  }
}

/* Small devices (landscape phones, 576px and up)*/
@media (max-width: 575.98px) {
  .mobile-navbar .brand-nav img {
    height: 30px !important;
  }

  .hero-content h1 {
    font-size: 24px !important;
  }

  .hero-content p {
    font-size: 16px !important;
  }

  .hero-content button {
    padding: 8px 15px !important;
    margin: 0px !important;
  }

  .what-wireswap h1 {
    font-size: 22px !important;
  }

  .what-wireswap p {
    font-size: 16px !important;
  }

  .section-header {
    font-size: 24px !important;
  }

  .roarmap-container h3 {
    font-size: 18px !important;
  }

  .roarmap-container h3 img {
    height: 17px;
  }

  .roarmap-container ul li {
    font-size: 15px;
  }

  .roarmap-container ul li img {
    height: 17px;
  }
}
