@charset "utf-8";
/* CSS Document */

@font-face {
  font-family: "Poppins-Bold";
  src: url("../fonts/Poppins-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Poppins-Medium";
  src: url("../fonts/Poppins-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Poppins-Regular";
  src: url("../fonts/Poppins-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

:root {
  --dark-color: #1a202c;
  --default-bg-color: #171923;
  --green-color: #bc27c5;
  --white-color: #ffffff;
  --gray-text-color: #718096;
  --blue-color: #0bc5ea;
}

* {
  margin: 0px;
  padding: 0px;
  font-family: "Poppins-Regular";
}

ul,
ol {
  list-style: none;
}

body {
  background-color: var(--default-bg-color);
  overflow-x: hidden;
}

/* Header */

.main-header {
  max-width: 1500px;
  margin: auto;
}

header {
  padding: 10px 0px;
  background-color: var(--dark-color);
  position: fixed;
  width: 100%;
  top: 0px;
  left: 0px;
  z-index: 999999999;
}

.nav-container-fluid {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.brand-nav img {
  height: 40px;
}

header a {
  font-family: "Poppins-Medium";
  font-size: 20px;
  color: var(--white-color) !important;
  padding: 0px 20px;
  transition: 0.5s;
}

.brand-nav a {
  padding: 0px;
}

header a:hover {
  color: var(--blue-color) !important;
}

.mobile-navbar {
  display: none;
}
.nav-container-fluid {
  display: flex;
}
@media (max-width: 480px) {
  .nav-container-fluid {
    display: none;
  }
}
@media (max-width: 480px) {
  .mobile-navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
.mobile-navbar .brand-nav img {
  height: 40px;
}

.mobile-navbar .navbar-toggler span {
  font-size: 22px;
  color: var(--white-color);
}

.mobile-navbar .navbar-toggler:focus {
  box-shadow: none !important;
}

.mobile-navbar .text-reset {
  background-color: transparent;
  border: none;
  outline: none;
}

.mobile-navbar .text-reset span {
  color: red;
  opacity: 0.5;
  font-size: 22px;
  transition: 0.5s;
}

.mobile-navbar .text-reset span:hover {
  opacity: 1;
}

.mobile-navbar .offcanvas-body .nav {
  flex-direction: column;
}

.mobile-navbar .offcanvas-body .nav a {
  font-size: 20px;
}

#offcanvasNavbarLabel {
  color: white;
  font-family: "Poppins-Bold";
}

/* Body Section */

.section-container {
  max-width: 1500px;
  margin: auto;
  padding-top: 6rem;
}

.hero-area {
  background-color: var(--dark-color);
  padding: 50px 0px 0px 0px;
  height: 100vh;
  display: flex;
  align-items: center;
}

.hero-area img {
  width: 100%;
}

.hero-area .row {
  height: 100%;
  align-items: center;
}

.hero-content h1 {
  font-family: "Poppins-Bold";
  color: var(--green-color);
  font-size: 60px;
  margin: 0px;
  padding-bottom: 15px;
}

.hero-content p {
  font-family: "Poppins-Regular";
  font-size: 22px;
  color: var(--gray-text-color);
}

.hero-content button {
  padding: 12px 25px;
  border: 1px solid transparent;
  font-family: "Poppins-Medium";
  font-size: 18px;
  margin: 5px;
  border-radius: 5px;
  outline: none;
  transition: 0.5s;
  background-color: var(--green-color);
  color: var(--white-color);
}

.hero-content button:hover {
  background-color: var(--blue-color);
}
.av:hover{
color: #ffffff !important;
}
.av{
  font-size: 16px !important;
}
.hero-content button:nth-child(4) {
  background-color: var(--white-color);
  color: var(--dark-color);
}

.hero-content button:nth-child(4):hover {
  border: 1px solid var(--blue-color);
  background-color: transparent;
  color: var(--blue-color);
}

/* wireswap introduction */

.what-wireswap img {
  width: 100%;
}

.what-wireswap h1 {
  font-family: "Poppins-Bold";
  color: var(--green-color);
  font-size: 40px;
  margin: 0px;
  padding: 0px 0px 20px 25px;
}

.what-wireswap p {
  font-family: "Poppins-Regular";
  font-size: 20px;
  color: var(--gray-text-color);
  padding: 0px 0px 0px 25px;
}

.what-wireswap p span {
  padding-top: 15px;
  display: block;
}

.what-wireswap label {
  margin: 0px;
  padding: 0px;
  color: var(--blue-color);
}

.roarmap-container {
  max-width: 800px;
  margin: auto;
}

.section-header {
  font-family: "Poppins-Bold";
  color: var(--blue-color);
  text-align: center;
}

.roarmap-container h3 {
  font-family: "Poppins-Medium";
  color: var(--green-color);
  font-size: 22px;
  margin-bottom: 15px;
}

.roarmap-container h3 img {
  padding-right: 15px;
  height: 20px;
}

#scrollspyRoadmap img {
  height: 100%;
  width: 100%;
  z-index: -1;
}

#scrollspyRoadmap {
  background-color: var(--dark-color);
}

.roarmap-container ul li {
  font-family: "Poppins-Regular";
  font-size: 18px;
  color: var(--white-color);
  margin-bottom: 10px;
  display: flex;
}

.roarmap-container ul li img {
  height: 20px;
  margin-right: 15px;
}

.comming-soon {
  font-family: "Poppins-Medium";
  font-size: 20px;
  color: var(--gray-text-color);
  text-align: center;
}

.audits img {
  display: block;
  margin: auto;
  margin-bottom: 30px;
}

footer {
  padding: 30px 0px;
  background-color: var(--dark-color);
}

footer ul li {
  display: inline-block;
  margin: 0px 15px;
}

footer ul li a span {
  font-size: 30px;
  color: var(--green-color);
  transition: 0.5s;
}

footer ul li a span:hover {
  color: var(--gray-text-color);
}

footer p {
  color: var(--gray-text-color);
  text-align: center;
}

.token-chart img {
  width: 100%;
}

.token-content h6 {
  display: flex;
  justify-content: space-between;
  color: var(--white-color);
  max-width: 500px;
  font-weight: bold;
  font-size: 20px;
}

.token-content {
  padding-right: 25px;
}

.token-content table {
  max-width: 600px;
}

.whitepaper {
  max-width: 1000px;
  margin: auto;
  padding: 30px 15px;
  background-color: var(--dark-color);
  border: 1px solid var(--gray-text-color);
  box-shadow: 0px 0px 5px 0px var(--gray-text-color) inset;
}

.whitepaper p {
  color: var(--gray-text-color);
}

.whitepaper p span {
  font-family: "Poppins-Bold";
  color: var(--green-color);
  display: block;
}
